const SettingsIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <g clipPath="url(#settingClipPath)">
        <path
          fill="#000"
          d="m14.947 6.507-.84-.3a.9.9 0 0 1-.54-.514.92.92 0 0 1 0-.74L14 4.14a1.58 1.58 0 0 0-2.107-2.107l-.813.387a.95.95 0 0 1-1.053-.217.9.9 0 0 1-.2-.323l-.3-.847a1.587 1.587 0 0 0-2.987 0l-.333.867a.93.93 0 0 1-.884.615 1 1 0 0 1-.37-.075l-.813-.407A1.58 1.58 0 0 0 2.033 4.14l.387.813a.92.92 0 0 1 0 .74.9.9 0 0 1-.54.514l-.84.3a1.587 1.587 0 0 0 0 2.986l.84.3a.9.9 0 0 1 .54.514.92.92 0 0 1 0 .74l-.387.813A1.58 1.58 0 0 0 4.14 14l.813-.38a.9.9 0 0 1 .74 0 .9.9 0 0 1 .514.54l.3.84a1.586 1.586 0 0 0 2.986 0l.3-.84a.9.9 0 0 1 .514-.54.9.9 0 0 1 .74 0l.813.38A1.58 1.58 0 0 0 14 11.86l-.387-.813a.92.92 0 0 1 0-.74.9.9 0 0 1 .54-.514l.84-.3a1.586 1.586 0 0 0 0-2.986zM8 11.193a3.33 3.33 0 0 1-2.96-1.966A3.2 3.2 0 0 1 6.773 5.04a3.247 3.247 0 0 1 4.187 1.733 3.2 3.2 0 0 1-1.733 4.187A3 3 0 0 1 8 11.193"
        />
      </g>
    </svg>
  );
};

export { SettingsIcon };
