const BookmarkUnselectedIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M1.85.05h12.3v15.9L8 12.362 1.85 15.95zm1.3 1.3v12.336L8 10.856l4.85 2.83V1.35z"
      />
    </svg>
  );
};

export { BookmarkUnselectedIcon };
