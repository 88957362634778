const SignOutIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M15.771 8a.9.9 0 0 1-.9.9H3.557a.9.9 0 0 1 0-1.8h11.314a.9.9 0 0 1 .9.9"
      />
      <path
        fill="#222"
        fillRule="evenodd"
        d="M15.771 8a.9.9 0 0 1-.263.636l-4.243 4.243a.9.9 0 1 1-1.273-1.273L13.6 8 9.992 4.393a.9.9 0 0 1 1.273-1.272l4.243 4.243A.9.9 0 0 1 15.77 8M5.802 1.956c-.044-.054-.075-.056-.082-.056H1.394c-.006 0-.038.002-.081.056a.43.43 0 0 0-.084.269v11.55c0 .121.04.215.084.27.043.053.075.055.081.055H5.72c.007 0 .038-.002.082-.056a.43.43 0 0 0 .084-.269v-3.3a.5.5 0 1 1 1 0v3.3c0 .626-.427 1.325-1.166 1.325H1.394c-.739 0-1.165-.699-1.165-1.325V2.225C.229 1.599.655.9 1.394.9H5.72c.739 0 1.166.699 1.166 1.325v3.3a.5.5 0 1 1-1 0v-3.3a.43.43 0 0 0-.084-.27"
      />
    </svg>
  );
};
export { SignOutIcon };
