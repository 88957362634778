const AppearanceIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={ariaLabel}
    >
      <path
        d="M10.395.001a8 8 0 0 0-7.63 5.173Zm-.747 1.326a6.7 6.7 0 1 0 3.308 12.802 8 8 0 0 1-5.035-5.53 8 8 0 0 1 1.727-7.272M10.395 0a1 1 0 0 1 .678 1.718l-.006.006a6.7 6.7 0 0 0-1.887 6.55A6.7 6.7 0 0 0 14 13.091h.001a1 1 0 0 1 .252 1.834 8 8 0 0 1-11.489-9.75"
        fill="#222"
        fillRule="evenodd"
      />
    </svg>
  );
};

export { AppearanceIcon };
