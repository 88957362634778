const SearchIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#000"
        d="m15.601 14.427-4.353-4.353a6.1 6.1 0 0 0 1.266-3.727 6.18 6.18 0 1 0-2.44 4.907l4.354 4.353a.84.84 0 0 0 1.173 0 .833.833 0 0 0 0-1.18M1.848 6.347a4.5 4.5 0 1 1 4.5 4.5 4.507 4.507 0 0 1-4.5-4.5"
      />
    </svg>
  );
};

export { SearchIcon };
