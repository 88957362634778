const CloseIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M.364.364a.9.9 0 0 1 1.272 0L8 6.727 14.364.364a.9.9 0 0 1 1.272 1.272L9.273 8l6.363 6.364a.9.9 0 0 1-1.272 1.272L8 9.273l-6.364 6.363a.9.9 0 1 1-1.272-1.273L6.727 8 .364 1.636a.9.9 0 0 1 0-1.272"
      />
    </svg>
  );
};

export { CloseIcon };
