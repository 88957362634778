const ShareIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M8.638.493A.897.897 0 0 0 7.38.476l-.016.016L3.12 4.735a.9.9 0 0 0 1.273 1.273L7.1 3.3v9.142a.9.9 0 0 0 1.8 0V3.3l2.706 2.707a.9.9 0 0 0 1.273-1.273zM1.9 8.28c0-.007.002-.038.056-.082a.43.43 0 0 1 .269-.084h3.3a.5.5 0 1 0 0-1h-3.3C1.599 7.114.9 7.541.9 8.28v6.326c0 .739.699 1.165 1.325 1.165h11.55c.626 0 1.325-.426 1.325-1.165V8.28c0-.739-.699-1.166-1.325-1.166h-3.3a.5.5 0 0 0 0 1h3.3c.121 0 .215.04.27.084.053.044.055.075.055.082v6.326c0 .006-.002.038-.056.081a.43.43 0 0 1-.269.085H2.225a.43.43 0 0 1-.27-.085c-.053-.043-.055-.075-.055-.081z"
      />
    </svg>
  );
};

export { ShareIcon };
