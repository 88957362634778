const ArrowLeftIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M6.847 13.16a.9.9 0 1 1-1.327 1.217L.236 8.607a.9.9 0 0 1 0-1.215L5.52 1.623A.9.9 0 0 1 6.847 2.84L2.945 7.1H15.1a.9.9 0 0 1 0 1.8H2.945z"
      />
    </svg>
  );
};

export { ArrowLeftIcon };
