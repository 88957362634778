const MembershipIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M8.037 1.09c-2.446 0-4.538 1.863-4.538 4.254a4 4 0 0 0 .466 1.878C1.575 8.092 0 10.833 0 13.77c0 .468.328 1.14 1.049 1.14H14.95c.721 0 1.049-.672 1.049-1.14 0-2.91-1.549-5.636-3.911-6.512.31-.572.485-1.221.485-1.914 0-2.391-2.092-4.254-4.537-4.254m3.107 7.348a4.7 4.7 0 0 1-3.107 1.159c-1.2 0-2.316-.449-3.14-1.189-1.839.391-3.396 2.46-3.492 5.102h13.19c-.096-2.628-1.634-4.68-3.451-5.072M4.899 5.344c0-1.518 1.362-2.854 3.138-2.854s3.137 1.336 3.137 2.854-1.362 2.853-3.137 2.853c-1.776 0-3.138-1.335-3.138-2.853"
      />
    </svg>
  );
};

export { MembershipIcon };
