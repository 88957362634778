const FollowSelectedIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        d="M7.798.04c.225-.101.543-.005.654.23q.141.305.286.609l.62 1.318.62 1.317.68 1.444.432.066 2.773.422.877.134.876.133c.174.027.322.217.366.383a.57.57 0 0 1-.134.534l-.232.236-.232.237-1.003 1.025-1.004 1.025-1.078 1.103.054.33q.12.723.237 1.447l.237 1.449.15.915.15.915c.036.222-.013.432-.189.575a.51.51 0 0 1-.58.042l-.287-.158-.286-.158-1.241-.684-1.24-.684L8 13.527l-.023.012-.286.158-1.241.685-1.24.683-.784.432-.784.432a.51.51 0 0 1-.58-.042c-.18-.144-.225-.353-.188-.575l.11-.669.623-3.811.094-.575-.042-.043-.231-.237-1.004-1.025-1.003-1.026L.152 6.63a.57.57 0 0 1-.134-.534c.046-.168.192-.358.366-.383q.32-.047.64-.097l2.773-.422.876-.133.668-.101q.09-.195.183-.389l.62-1.317.62-1.318.762-1.619.01-.02.012-.026a.45.45 0 0 1 .25-.231"
      />
    </svg>
  );
};

export { FollowSelectedIcon };
