const EditIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M12.335 0a.65.65 0 0 1 .46.19l3.015 3.016a.65.65 0 0 1 0 .919l-9.423 9.423a.7.7 0 0 1-.204.138L.906 15.948a.65.65 0 0 1-.853-.854l2.262-5.278a.7.7 0 0 1 .138-.203L11.876.19a.65.65 0 0 1 .46-.19M3.46 10.443l-1.572 3.669 3.668-1.573 8.875-8.875-2.096-2.096z"
      />
      <path
        fill="#222"
        fillRule="evenodd"
        d="M10.024 2.253a.5.5 0 0 1 .708 0l3.014 3.016a.5.5 0 0 1-.707.707L10.024 2.96a.5.5 0 0 1 0-.707M2.559 9.719a.5.5 0 0 1 .707 0l3.015 3.016a.5.5 0 1 1-.708.707l-3.015-3.016a.5.5 0 0 1 0-.707"
      />
    </svg>
  );
};

export { EditIcon };
