const PushIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M8.5 3.292a4.463 4.463 0 0 0-4.463 4.462v4.154q0 .16-.03.31h8.985q-.03-.15-.03-.31V7.754A4.463 4.463 0 0 0 8.5 3.292m6.071 8.925a.31.31 0 0 1-.308-.309V7.754a5.763 5.763 0 1 0-11.526 0v4.154a.31.31 0 0 1-.308.31.65.65 0 1 0 0 1.3H14.57a.65.65 0 0 0 0-1.3"
      />
      <path
        fill="#222"
        fillRule="evenodd"
        d="M8.5.075a.65.65 0 0 1 .65.65v1.917a.65.65 0 0 1-1.3 0V.725a.65.65 0 0 1 .65-.65m-1.665 13.58a.5.5 0 0 1 .59.39 1.098 1.098 0 0 0 2.15 0 .5.5 0 0 1 .98.2 2.098 2.098 0 0 1-4.11 0 .5.5 0 0 1 .39-.59M9.65 4.636a.5.5 0 0 1 .67-.225 3.38 3.38 0 0 1 1.875 3.024.5.5 0 0 1-1 0c0-.903-.51-1.727-1.32-2.128a.5.5 0 0 1-.225-.67"
      />
    </svg>
  );
};

export { PushIcon };
