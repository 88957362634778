const ArrowRightIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M9.208 1.568a.9.9 0 0 1 1.272.055l5.284 5.77a.9.9 0 0 1 0 1.215l-5.284 5.769a.9.9 0 1 1-1.327-1.216L13.055 8.9H.9a.9.9 0 1 1 0-1.8h12.155L9.153 2.84a.9.9 0 0 1 .055-1.272"
      />
    </svg>
  );
};

export { ArrowRightIcon };
