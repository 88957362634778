const CaretUpIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        d="m8 5.519-6.247 7.14A1 1 0 0 1 .247 11.34l7-8a1 1 0 0 1 1.506 0l7 8a1 1 0 0 1-1.506 1.318z"
      />
    </svg>
  );
};

export { CaretUpIcon };
