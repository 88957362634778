import cn from "classnames";
import Link from "next/link";
import { Button as AriaButton } from "@ariakit/react";

import type { ReactElement } from "react";

import styles from "./BaseButton.module.scss";

const BaseButton = ({
  content,
  onClick,
  href,
  size,
  variant,
  isPremium,
  slug,
  id,
  type,
  className,
  isExternalLink,
  tabIndex,
}: {
  content: ReactElement;
  onClick?: () => void;
  href?: string;
  size: "large" | "small" | "tiny" | "compact";
  isPremium?: boolean;
  variant: "primary" | "secondary" | "icon" | "text";
  slug: string;
  id?: string;
  type: string;
  className?: string;
  isExternalLink?: boolean;
  tabIndex?: number;
}) => {
  const buttonClassName = cn([
    styles.button,
    isPremium && styles.premium,
    styles[size],
    styles[variant],
    className,
  ]);

  if (type == "button" || type === "submit") {
    return (
      <AriaButton
        type={type}
        className={buttonClassName}
        onClick={onClick}
        data-slug={slug}
        id={id}
        tabIndex={tabIndex}
      >
        {content}
      </AriaButton>
    );
  }
  if (type === "link") {
    return (
      <Link
        className={buttonClassName}
        onClick={onClick}
        data-slug={slug}
        href={href || ""}
        id={id}
        target={isExternalLink ? "_blank" : undefined}
        tabIndex={tabIndex}
        prefetch={false}
      >
        {content}
      </Link>
    );
  }
};

export { BaseButton };
