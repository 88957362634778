const RefreshIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M9.632 2.059a6.163 6.163 0 0 0-7.795 5.94v.27l-.898-.897a.55.55 0 1 0-.778.778l1.987 1.986a.55.55 0 0 0 .778 0L4.912 8.15a.55.55 0 1 0-.778-.778l-.897.898v-.271a4.763 4.763 0 0 1 8.436-3.03.7.7 0 1 0 1.08-.89 6.16 6.16 0 0 0-3.121-2.02m3.125 6.185-.891.892a.55.55 0 1 1-.778-.778l1.986-1.987a.55.55 0 0 1 .778 0l1.987 1.987a.55.55 0 1 1-.778.778l-.902-.903a6.164 6.164 0 0 1-10.641 3.995.7.7 0 0 1 1.018-.96 4.764 4.764 0 0 0 8.221-3.024"
      />
    </svg>
  );
};

export { RefreshIcon };
