const NewsmixIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M5.882 1.704a2.219 2.219 0 0 1 4.211.032H15.3a.7.7 0 1 1 0 1.4h-5.22a2.219 2.219 0 0 1-4.207-.032H.7a.7.7 0 1 1 0-1.4zm2.1-.504a1.218 1.218 0 1 0 0 2.435 1.218 1.218 0 0 0 0-2.435M.7 8.7h1.742a2.219 2.219 0 0 0 4.205.012H15.3a.7.7 0 1 0 0-1.4H6.656A2.219 2.219 0 0 0 2.442 7.3H.7a.7.7 0 1 0 0 1.4m3.847-1.918a1.218 1.218 0 1 0 0 2.436 1.218 1.218 0 0 0 0-2.436m-.388 7.5H.7a.7.7 0 1 1 0-1.4h3.46a2.219 2.219 0 0 1 4.21 0h6.93a.7.7 0 1 1 0 1.4H8.37a2.219 2.219 0 0 1-4.21 0m.888-.7a1.218 1.218 0 1 1 2.435 0 1.218 1.218 0 0 1-2.435 0"
      />
    </svg>
  );
};

export { NewsmixIcon };
