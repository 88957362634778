const CaretLeftIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        d="m5.52 8 7.14 6.25a1 1 0 0 1-1.32 1.5l-8-7a1 1 0 0 1 0-1.5l8-7a1 1 0 0 1 1.32 1.5z"
      />
    </svg>
  );
};

export { CaretLeftIcon };
