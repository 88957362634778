const MenuIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M0 2.73a.9.9 0 0 1 .9-.9h14.2a.9.9 0 1 1 0 1.8H.9a.9.9 0 0 1-.9-.9M0 8a.9.9 0 0 1 .9-.9h14.2a.9.9 0 1 1 0 1.8H.9A.9.9 0 0 1 0 8m0 5.27a.9.9 0 0 1 .9-.9h14.2a.9.9 0 0 1 0 1.8H.9a.9.9 0 0 1-.9-.9"
      />
    </svg>
  );
};

export { MenuIcon };
