const CheckmarkIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M15.572 1.236a1 1 0 0 1 .16 1.405l-9.51 11.963a1 1 0 0 1-1.506.068L.326 10.07a1 1 0 1 1 1.448-1.38l3.597 3.77 8.796-11.065a1 1 0 0 1 1.405-.16"
      />
    </svg>
  );
};

export { CheckmarkIcon };
