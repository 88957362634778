const FollowUnselectedIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        d="m7.55.91.781 1.66L9.56 5.176c.089.191.172.386.27.573.146.282.44.275.698.314q1.31.201 2.62.399l2.06.314.036.005-.227-.904-1.265 1.292q-.993 1.013-1.987 2.03c-.145.148-.294.294-.437.446-.143.155-.168.332-.136.537l.012.069.053.324.45 2.742.357 2.185.76-.611-1.563-.86-2.457-1.353c-.179-.099-.358-.202-.538-.297a.53.53 0 0 0-.532 0l-.058.032-.279.153q-1.175.646-2.348 1.295l-1.841 1.015-.031.018.759.612q.15-.912.297-1.823l.47-2.868.103-.63c.033-.205.007-.381-.136-.537L4.622 9.6l-.225-.231-1.9-1.94-1.49-1.523-.025-.026-.227.904L2.5 6.517l2.745-.418c.2-.031.405-.051.603-.092.278-.058.378-.385.488-.617L7.51 2.9 8.43.945l.016-.034a.6.6 0 0 0 .051-.417.54.54 0 0 0-.237-.322c-.232-.13-.578-.078-.705.194L6.78 2.011 5.555 4.613l-.282.6q.156-.123.308-.248l-1.73.263-2.74.417q-.315.049-.63.095c-.173.026-.317.213-.362.38a.56.56 0 0 0 .132.526l1.253 1.28 1.982 2.025.457.467-.132-.526-.297 1.808-.468 2.86-.108.66c-.036.22.008.426.186.568a.5.5 0 0 0 .572.042l1.549-.853 2.45-1.351.566-.312h-.522l1.549.853 2.45 1.35.566.313a.5.5 0 0 0 .572-.042c.174-.14.222-.348.186-.568l-.296-1.807-.468-2.86-.108-.66-.133.526 1.253-1.28 1.982-2.025.457-.467a.56.56 0 0 0 .133-.527c-.043-.164-.19-.352-.361-.379q-.866-.13-1.731-.263l-2.739-.416-.631-.096.308.249-.775-1.645L8.73.968q-.143-.3-.283-.6c-.12-.255-.483-.345-.705-.195-.255.165-.32.465-.19.737"
      />
    </svg>
  );
};

export { FollowUnselectedIcon };
