const AddIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-6.6a6.6 6.6 0 1 0 0 13.2A6.6 6.6 0 0 0 8 1.4m0 2.018a.6.6 0 0 1 .6.6V7.4h3.382a.6.6 0 1 1 0 1.2H8.6v3.382a.6.6 0 1 1-1.2 0V8.6H4.018a.6.6 0 1 1 0-1.2H7.4V4.018a.6.6 0 0 1 .6-.6"
      />
    </svg>
  );
};

export { AddIcon };
