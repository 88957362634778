const SignInIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#000"
        d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m0 14.667A6.666 6.666 0 1 1 8 1.334a6.666 6.666 0 0 1 0 13.333"
      />
      <path
        fill="#000"
        d="M8 3.333A2.667 2.667 0 0 0 6.62 8.28l-.5 3.233a1.01 1.01 0 0 0 .233.807 1 1 0 0 0 .76.347h1.774a1 1 0 0 0 .964-.725q.06-.211.029-.429l-.5-3.233A2.666 2.666 0 0 0 8 3.333"
      />
    </svg>
  );
};

export { SignInIcon };
