const CaretRightIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        d="m10.481 8-7.14 6.247a1 1 0 0 0 1.318 1.506l8-7a1 1 0 0 0 0-1.506l-8-7a1 1 0 0 0-1.317 1.506z"
      />
    </svg>
  );
};

export { CaretRightIcon };
