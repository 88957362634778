const BackIcon = () => {
  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="back-secondary">
        <path
          id="Path Copy"
          d="M7.48141 8.58594L0.341512 14.8334C-0.0741241 15.197 -0.116242 15.8288 0.247439 16.2444C0.611121 16.6601 1.24288 16.7022 1.65852 16.3385L9.6585 9.33851C10.1138 8.9401 10.1138 8.23177 9.6585 7.83336L1.65852 0.833374C1.24288 0.469692 0.61112 0.511811 0.247438 0.927447C-0.116243 1.34308 -0.0741253 1.97484 0.341511 2.33852L7.48141 8.58594Z"
          fill="#222222"
        />
      </g>
    </svg>
  );
};

export { BackIcon };
