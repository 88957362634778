const CaretDownIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        d="m8 10.48 6.25-7.14a1 1 0 0 1 1.5 1.32l-7 8a1 1 0 0 1-1.5 0l-7-8a1 1 0 0 1 1.5-1.32z"
      />
    </svg>
  );
};

export { CaretDownIcon };
